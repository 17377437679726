import React, { useState } from "react";
import Layout from "../../../components/Common/Layout";
import { HttpRequest } from "../../../utils/common";
import PaymentButton from "../../../components/Common/Buttons/PaymentButton";
import VisaCardImg from "../../../../images/card_visa.jpg";
import MasterCardImg from "../../../../images/card_mastercard.jpg";
import JCBCardImg from "../../../../images/card_jcb.jpg";
import AmexCardImg from "../../../../images/card_amex.jpg";
import DiscoverCardImg from "../../../../images/card_discover.jpg";
import DinersCoardImg from "../../../../images/card_diners.jpg";

type Props = {
  payJpPublicKey: string;
  cardBrand: string;
  cardLast4: number;
  cardExpMonth: number;
  cardExpYear: number;
};

const Index: React.FC<Props> = (props) => {
  const {
    payJpPublicKey,
    cardBrand,
    cardLast4,
    cardExpMonth,
    cardExpYear
  } = props;

  const [card_brand, setBrand] = useState<string>(cardBrand);
  const [card_last4, setLast4] = useState<number>(cardLast4);
  const [card_exp_month, setExpMonth] = useState<number>(cardExpMonth);
  const [card_exp_year, setExpYear] = useState<number>(cardExpYear);

  const cardRenewalCallback = async (payjp_token: string) => {
    HttpRequest.put('/api/accounts/payment_object_id', { payjp_token })
      .then(async ({ data }) => {
        setBrand(data.brand)
        setLast4(data.last4)
        setExpMonth(data.exp_month)
        setExpYear(data.exp_year)
      })
      .catch((error) => {
        console.log(error)
        return
      })
  }

  const getBrandIconUrl = (brand) => {
    switch (brand) {
      case 'Visa':
        return VisaCardImg;
      case 'MasterCard':
        return MasterCardImg;
      case 'JCB':
        return JCBCardImg;
      case 'American Express':
        return AmexCardImg;
      case 'Diners Club':
        return DinersCoardImg;
      case 'Discover':
        return DiscoverCardImg;
      default:
        return null;
    }
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: " クレジットカード情報の登録・変更",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-10 sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          クレジットカード情報の登録・変更
        </h2>
        { card_brand ? (
            <div className="sm:w-[252px] sm:mx-auto mb-6">
              <p className="mb-4 text-lg font-semibold">ご使用中のクレジットカード</p>
              <div className="flex items-center mb-2">
                <img 
                  src={getBrandIconUrl(card_brand)}
                  alt={`${card_brand}`}
                  className="mr-2"
                  style={{height: '30px', width: 'auto' }}
                />
              </div>
              <p className="mb-2">XXXX-XXXX-XXXX-{card_last4}</p>
              <p>
                {card_exp_month}/{card_exp_year}
              </p>
            </div>
          ) : (
            <div className="sm:w-[252px] sm:mx-auto mb-6">
              登録中のカードはありません
            </div>
          )
        }
        <div className="sm:w-[252px] sm:mx-auto mb-3 h-[50px]">
          <PaymentButton
            disabled={() => false}
            submitText="カードを登録・変更する"
            text="カードを登録・変更する"
            payJpPublicKey={payJpPublicKey}
            callback={cardRenewalCallback}
            isAddressEdited={false}
            postal_code=""
            full_address=""
          />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="link-hover-none left-icon icon-stick-arrow-left text-sm"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
